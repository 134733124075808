import React from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import PageWrapper from '../components/PageWrapper'

const Contact = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: 'right',
          button: 'null', // cta, account, null
        }}
        footerConfig={{
          style: 'style1',
        }}
        seoConfig={{
          title: 'Contact us',
        }}
      >
        <div className="inner-banner pt-24 pb-md-11 bg-default-2">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9">Hire Us!</h2>
                  <p className="gr-text-8 mb-13">
                    We are available for your next tech project. Please fill out
                    the form below and we will be in touch shortly.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="contact-section pb-5 pb-md-29 bg-default-2 ">
          <Container>
            <Row className="justify-content-center">
              <Col
                sm="5"
                md="6"
                lg="5"
                xl="5"
                className="offset-lg-1 d-flex flex-column mb-9 order-sm-2"
              >
                <div className="single-contact-widget mb-13">
                  <h3 className="gr-text-6 mb-5">Email us</h3>
                  <p className="gr-text-7 mb-0">hello@dotdotdata.com</p>
                </div>
                <div className="single-contact-widget mb-13">
                  <h3 className="gr-text-6 mb-5">Call us</h3>
                  <p className="gr-text-7 mb-0">(956) 624-0448</p>
                </div>
                <div className="single-contact-widget">
                  <h3 className="gr-text-6 mb-5">Visit us</h3>
                  <p className="gr-text-7 mb-0">
                    601 N Main St.
                    <br />
                    McAllen, TX 78504
                  </p>
                </div>
              </Col>
              <Col sm="7" md="6" lg="5" xl="4" className="order-sm-1">
                <div className="login-form bg-white border-gray-3 px-7 pt-8 pb-9 shadow-1 rounded-10">
                  <form action="https://formspree.io/f/mknpzbdo" method="POST">
                    <input
                      type="text"
                      name="_gotcha"
                      style={{ display: 'none' }}
                    />
                    <input
                      type="hidden"
                      name="_subject"
                      value="Dot Dot Data Contact Form Submission"
                    />
                    <div className="form-group">
                      <label
                        htmlFor="name"
                        className="gr-text-11 font-weight-bold text-blackish-blue"
                      >
                        Name
                      </label>
                      <input
                        className="form-control px-7 gr-text-11 border"
                        type="text"
                        name="name"
                        placeholder="Tony Stark"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="name"
                        className="gr-text-11 font-weight-bold text-blackish-blue"
                      >
                        Email
                      </label>
                      <input
                        className="form-control px-7 gr-text-11 border"
                        type="email"
                        name="email"
                        placeholder="tony@starkindustries.com"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="name"
                        className="gr-text-11 font-weight-bold text-blackish-blue"
                      >
                        Subject
                      </label>
                      <input
                        className="form-control px-7 gr-text-11 border"
                        type="text"
                        name="subject"
                        placeholder="I need a mobile app"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="name"
                        className="gr-text-11 font-weight-bold text-blackish-blue"
                      >
                        Message
                      </label>
                      <textarea
                        name="message"
                        className="form-control px-7 gr-text-11 border"
                        placeholder="Tell us about your project"
                        required
                      ></textarea>
                    </div>
                    <div className="button-block mb-2">
                      <Button className="form-btn w-100" type="submit">
                        Send
                      </Button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  )
}

export default Contact
